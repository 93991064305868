<script setup lang="ts">
import ContactSupportModal from "./ContactSupportModal.vue";
import ContactSalesModal from "./ContactSalesModal.vue";
import { useSupportStore } from "@/store/support";

interface Emits {
  (e: "contact-modal-open"): void;
}

const emit = defineEmits<Emits>();

const supportStore = useSupportStore();
const { contactSalesModal, contactSupportModal } = storeToRefs(supportStore);

watch([contactSalesModal.value, contactSupportModal.value], ([salesModal, supportModal]) => {
  if (salesModal.active || supportModal.active) emit("contact-modal-open");
});
</script>

<template>
  <ContactSupportModal />
  <ContactSalesModal />
</template>
