<script setup lang="ts">
import { useSupportStore } from "@/store/support";

const { handleSubmit, isSubmitting } = useForm();
const supportStore = useSupportStore();
const { contactSalesModal } = storeToRefs(supportStore);

const toggleContactSalesModal = (active: boolean) => {
  supportStore.toggleContactSalesModal({ active });
};

const sendSalesEmail = handleSubmit(async () => {
  try {
    await supportStore.sendContactSalesEmail();
    showNotifySuccess("Thank you. We will get back to you as soon as possible.");
  } catch (e: any) {
    showNotifyError(e.message ?? ERROR_MESSAGES.error_submitting_sales_request);
  }
});
</script>

<template>
  <DialogModal :show="contactSalesModal.active">
    <template #title>Contact sales</template>
    <template #content>
      <div class="space-y-4">
        <p>
          If you need a bespoke, flexible offering, please contact our sales team. We'll get back to you as soon as
          possible.
        </p>
        <p>Please describe your requirements in the field below.</p>
        <FormTextarea
          v-model="contactSalesModal.message"
          :disabled="isSubmitting"
          rows="3"
          validate-as="required:Requirements"
          id="sales-message"
          placeholder="Please describe your requirements"
          name="sales-message"
          aria-label="Please describe your requirements"
          class="w-full"
        />
      </div>
    </template>
    <template #footer>
      <OutlineButton @click="toggleContactSalesModal(false)" :disabled="isSubmitting"> Cancel </OutlineButton>
      <PrimaryButton @click="sendSalesEmail" class="ml-3" :spinner="isSubmitting" :disabled="isSubmitting">
        Submit
      </PrimaryButton>
    </template>
  </DialogModal>
</template>
