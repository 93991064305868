<script setup lang="ts">
import { useSupportStore } from "@/store/support";

const { handleSubmit, isSubmitting } = useForm();
const supportStore = useSupportStore();
const { contactSupportModal } = storeToRefs(supportStore);

const toggleContactSupportModal = (active: boolean) => {
  supportStore.toggleContactSupportModal({ active });
};

const sendSupportEmail = handleSubmit(async () => {
  try {
    await supportStore.sendContactSupportEmail();
    showNotifySuccess("Support request successfully sent");
  } catch (e: any) {
    showNotifyError(e.message ?? ERROR_MESSAGES.error_submitting_support_request);
  }
});
</script>

<template>
  <DialogModal :show="contactSupportModal.active">
    <template #title>Contact support</template>
    <template #content>
      <div class="space-y-4">
        <p>If you need help, please contact our support team. We'll get back to you as soon as possible.</p>
        <p>Please describe your issue in the field below.</p>
        <FormTextarea
          v-model="contactSupportModal.message"
          :disabled="isSubmitting"
          rows="3"
          validate-as="required:Description"
          id="support-message"
          placeholder="Please describe your issue"
          name="support-message"
          aria-label="Please describe your issue"
          class="w-full"
        />
      </div>
    </template>
    <template #footer>
      <OutlineButton @click="toggleContactSupportModal(false)" :disabled="isSubmitting"> Cancel </OutlineButton>
      <PrimaryButton @click="sendSupportEmail" class="ml-3" :spinner="isSubmitting" :disabled="isSubmitting"> Submit </PrimaryButton>
    </template>
  </DialogModal>
</template>
